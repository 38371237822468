import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { tap } from 'rxjs/operators/tap';


@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(private authService :NbAuthService, private router: Router){
    }



  canActivate() {
//     var index;
// var count = localStorage.length;
// for( index = 0; index < count; index++ )
// {
// }




    return this.authService.isAuthenticated()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            //this.router.navigate(['auth/login']);
            this.router.navigate(['/login']);
          }
        }),
      );
   //return this.authService.isAuthenticated();
   //return true;
  }
}