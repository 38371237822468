import { Component, Inject, OnInit , Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog  } from '@angular/material/dialog';
import { ApiService } from '../shared/api.service';


@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: `./confirmation-dialog.component.html`,
})
export class ConfirmationDialogComponent implements OnInit{
  type: string;
  appl_id: string;
  note: string = '';
  id: string;
  tracker : string;
  user_id : any;
  admin_notes:any;

 
  constructor(
    private api: ApiService,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
      this.type = data.type;
      this.appl_id = data.appl_id;
      this.id = data.id;
      this.tracker = data.tracker;
      this.user_id = data.user_id;
      this.admin_notes = data.admin_notes;
  }
 
 
  ngOnInit(): void {
  }
  
  onAccept(): void {
    this.dialogRef.close('accept');
  }

  onReject(): void {
    this.dialogRef.close('reject');
  }
  
  onSaveClick(note): void{
    this.api.saveNote(note,this.id,this.tracker,this.user_id).subscribe(data=>{
      this.dialogRef.close();
    },
    (error) => {  
      console.error("Error saving note:", error);
    }
  );
}
    

  onCloseClick(): void{
    this.dialogRef.close('reject');
  }
}