// export var config = {
//   serverUrl : "http://localhost:3005",
 
//   ENV_sendgrid_Twilio : "production",
//   transUploadUrl:"http://localhost:3005/api/attestation/upload_document",
//   markListUploadUrl : "http://localhost:3005/api/attestation/uploadUserMarkList",
//   curriculumUploadUrl: "http://localhost:3005/api/attestation/upload_curriculum",
//   docUploadUrl :  "http://localhost:3005/api/etranscript/upload_doc",
// }

export var config = {
  serverUrl : "https://devsouattestation.studentscenter.in",    
  socketioUrl : "http://localhost:2",
  ENV_sendgrid_Twilio : "production",
  markListUploadUrl : "https://devsouattestation.studentscenter.in/api/attestation/uploadUserMarkList",
  transUploadUrl:"https://devsouattestation.studentscenter.in/api/attestation/upload_transcript",
  curriculumUploadUrl: "https://devsouattestation.studentscenter.in/api/attestation/upload_curriculum",
  letterUploadUrl : "https://devsouattestation.studentscenter.in/api/attestation/upload_gradeToPercentLetter",
  production: true,
  PaymentIssueUrl :"https://devsouattestation.studentscenter.in/api/attestation/Upload_PaymentIssueUrl",
}