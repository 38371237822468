import { Component, Input } from '@angular/core';
import { NbDialogRef,NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';
import { ApiService } from '../../shared/api.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
    selector: 'nb-dialog',
	template: `
    <nb-card>
      <nb-card-header id="header">Verify Otp Here</nb-card-header>
      <nb-card-body>
		<div class="row">
			<span style="color :red;">If OTP is not received on Mobile. Please check your registered email ID account. Email is sent and sometimes land on spam folder.
			</span>
			<input [(ngModel)]="otp" id="otp" style="visibility: visible;" nbInput type="text" placeholder="Enter Otp Here"  class="form-control"> 
			<span *ngIf="otpValidation===false" style="color:red;">Please enter OTP</span> 
			<span *ngIf="otpSent===false" style="color:red;">Please check your mobile for the OTP</span> 
			<form [formGroup]="otpFormGroup">
			<div class="form-group">
			<div class="row" *ngIf="resend_input===false" >
				<input nbInput placeholder="Mobile No" fullWidth fieldSize="large" formControlName="mobileCtrl"
				[ngClass]="{'form-control-danger': otpFormGroup.controls.mobileCtrl.invalid && (otpFormGroup.controls.mobileCtrl.dirty || otpFormGroup.controls.mobileCtrl.touched)}"
				inputmode="numeric" digitOnly ng2TelInput 
				[ng2TelInputOptions]="{separateDialCode:true}" 
				(countryChange) = "onCountryChange($event)" 
				(keypress)="OnlyNoAllowed($event)"
				required>
			</div> 
			</div> 
		 </form>
			<span *ngIf="resend_input_validation===false" style="color:red;">Please enter OTP</span> 
		</div>         
		<div *ngIf="alert == 200">
		<h2>Otp Verified Successfully.</h2>	  
		</div>
		<div *ngIf="alert == 400">
			<h3 style="color:red;">Please Enter Valid OTP. </h3>
		</div>   
      </nb-card-body>
	  <nb-card-footer>
		<div class="row">
			<div class="col-md-2"></div>
			<div class="col-md-2"></div>
			<div class="col-md-3"></div>
			<div class="col-md-5">
			<a  style="cursor:pointer;color: #034c83;" (click)="resend_otp();update_number_otp()"  >Resend SMS</a> &nbsp; &nbsp; &nbsp; &nbsp;
			<a  style="cursor:pointer;color: #034c83;" (click)="resend_input_open()"  >Resend SMS With Different Mobile No</a>
			</div>
    	</div>
   		<div class="row">
		   <div class="col-lg-6"><button nbButton *ngIf="resend_input===false" (click)="resend_otp_diff();update_number_otp()" hero status="primary" id="resend" style="visibility: visible;margin-top:10px;" >Update & Send</button></div>
    	</div>
	  
		<div class="row">
		<div class="col-lg-3"><button nbButton outline status="primary" (click)="verify(3000,'bottom-end')" >Verify</button></div>           
	 
		</div>          
        </nb-card-footer>
	</nb-card>
	
    `,
    })

export class OtpComponent {
@Input() email:string;
@Input()  password:string;
@Input()  userCountryCode:string;
@Input()    userContactNo :string;
@Input()    user_id :string;
otp;
alertflag=0;
otpalertflag=0;
alert;
otpSent:boolean = true;
resend;
resend_input_validation:boolean = true;
resend_input:boolean = true;
contact ;
mobile;
phone_code;
otpValidation;
defaultCountryCode: boolean;
countryCode: any;
resendOtp: boolean;
resendOtpDiff: boolean;
otpFormGroup : FormGroup;

    constructor(protected ref: NbDialogRef<OtpComponent>,
      private router : Router,
			protected api : ApiService,
			private toastrService: NbToastrService,
			private fb : FormBuilder,
			private toasterService : NbToastrService,) {
    }

	ngOnInit() {

        this.otpFormGroup = this.fb.group({
          mobileCtrl:[''],
        });
	}

    verify(duration,position){
        var register_data={
         email:this.email,
         password:this.password,
					otp:this.otp,
					type:'register'
		}

		if(this.otp != undefined){

			this.api.Otpvalue(register_data)
			.subscribe(
			  (data: any) => {
	
				if(data['status'] == 200){
				  this.alert=data['status'] ;
					this.toastrService.show(
						`Otp Verified Successfully ! `,{duration},{position}
					);
					this.router.navigate(['auth/login'])
					this.ref.close();
							this.alertflag=1;        
		
				  }
				  else if(data['status'] == 400){
					this.alert=data['status'] ;
				  }
	
	
			  });
		}
		
	

    }

	ok(){
		this.router.navigate(['auth/login'])
		this.ref.close();
	}
    close()
    {
        this.alertflag=0; 

    }
    dismiss() {
       
        this.ref.close();
        
		}
	
		update_number_otp(){
			if(!(this.defaultCountryCode)){
				this.countryCode = '1';
			}  if(this.resendOtp){
				var data={
					phone_code : null,
					mobile : null,
					user_id :this.user_id
				}
				
			  }
			  if(this.resendOtpDiff){
				 var data={
					phone_code : this.countryCode,
					mobile : this.otpFormGroup.controls.mobileCtrl.value,
					user_id : this.user_id
				} 
				
			  }
				  
				this.api.UpdateNumberOTP(data)
				.subscribe(
			  	(data: any) => {
		
			  	if(data['status'] == 200){
					this.resend_input = true;
					this.otpSent = true;
					this.toasterService.success('otp send successfully!!','Success',{duration: 3000});
				}
				else if(data['status'] == 400){
					this.resend_input = true;
					this.toasterService.danger('Unable to send otp  Please try again','Error',{duration: 3000});
				}
			
			});
		  }
		  
		resend_input_open(){
			// this.mobile=this.userContactNo;
			// this.phone_code=this.userCountryCode;
			this.resend_input = false;
			this.otpFormGroup = this.fb.group({
				mobileCtrl:[''],
			  });
		}
		   
		OnlyNoAllowed(event): boolean {
			const charCode = event.which ? event.which : event.keyCode;
			if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			  
			  return false;
			}
			return true;
		}
		  
		onCountryChange(details){ 
			this.defaultCountryCode = true;
			this.countryCode = details.dialCode;
		}
		resend_otp(){
			this.resendOtp = true;
			
			this.resendOtpDiff = false;
			
		}
		resend_otp_diff(){
			this.resendOtpDiff = true;
			
			this.resendOtp = false;
			
		}		   
    
}