import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../shared/api.service';
import { Router,ActivatedRoute } from '@angular/router';
import { NbAuthJWTToken,NbAuthService } from '@nebular/auth';
@Component({
  selector: 'ngx-admin-otp',
  templateUrl: './admin-otp.component.html',
  styleUrls: ['./admin-otp.component.scss']
})
export class AdminOtpComponent {
  otp: any;
  alert: any;
  alertflag: number;
  user_id: any;
  enterOtp:any;
  otpValidation: boolean;
  menuArray:string[];
  constructor(private router : Router,
    private route : ActivatedRoute,
    protected api : ApiService,
    private authService : NbAuthService) {
      this.authService.onTokenChange()
        .subscribe((token: NbAuthJWTToken) => {
        this.menuArray=token.getPayload()['roles'];
        this.user_id=token.getPayload()['id'];
        if(token.getPayload()['role'] !="admin" && token.getPayload()['role'] !="sub-admin"){
          this.router.navigate(['auth/logout'])
        }
      });
    }

  ngOnInit() {
    this.api.postVerifiedOtp(this.user_id,false).subscribe((resp)=>{
    })
    this.api.sendOtp().subscribe(data=>{
      if(data['status'] == 200){
        this.otp = data['data']
      }
    })
  }
  
  resendotp(){
    this.api.sendOtp().subscribe(data=>{
      if(data['status'] == 200){
        this.otp = data['data']
      }
    })
  }

  cancel(){
    this.router.navigate(['auth/logout']);
  }


  verify(){
    this.enterOtp = (document.getElementById('enterOtp') as HTMLInputElement).value;
    if(this.otp === this.enterOtp){  
        this.api.updateOtp().subscribe(data=>{
        if(this.menuArray.includes('student-management')){
                this.api.postVerifiedOtp(this.user_id,true).subscribe((resp)=>{
             
                })
                this.router.navigate(['pages/student-management'])
              }else{
                this.api.postVerifiedOtp(this.user_id,true).subscribe((resp)=>{
                  
                })
                this.router.navigate([`pages/${this.menuArray[0]}`])
              }
        })       
    }else{
      this.otpValidation = false;
      
    }
  }
}
