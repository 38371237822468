import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  panelOpenState = false;
  constructor() { }

  ngOnInit() {
    window.location.href = 'https://souattestation.studentscenter.in/app/#/auth/login';
  }

  addTotargetGroup(node){
    document.getElementById('mainCheckBox').remove();
}

}
