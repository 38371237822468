
import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../shared/api.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'otp',
  templateUrl: './otp.component.html',
})
export class OTPComponent {
  @Input() email:string;
  @Input()  password:string;
  otp;
  otpValidation:boolean = true;
  alertflag=0;
  otpalertflag=0;
  alert;
  mobile = "+";
  otpSent:boolean = true;
  resend;
  user_id;
  resend_input_validation:boolean = true;
  resend_input:boolean = true;
  loggedInUser; 
  update_phone_message :string;
  defaultCountryCode: boolean;
  countryCode: any;
  otpFormGroup : FormGroup;
  resendOtp: boolean = false;
  countryCode1: string;
  contact: string;
  resendOtpDiff: boolean = true;

      constructor(
        private toasterService : NbToastrService,
        private fb: FormBuilder,
        private router : Router,
        protected api : ApiService,
        private route : ActivatedRoute) {
      }
  
      verify(){
        var register_data = {
          email:this.email,
          password:this.password,
          otp:this.otp,
          user_id : this.user_id
        }
  
      if(this.otp != undefined){
  
        this.api.Otpvalue(register_data)
        .subscribe(
          (data: any) => {
    
          if(data['status'] == 200){
            this.alert=data['status'] ;
            document.getElementById('otp').style.visibility = 'hidden';
            document.getElementById('verify').style.visibility = 'hidden';
            document.getElementById('header').style.visibility = 'hidden';
            this.alertflag=1;        
      
          }
          else if(data['status'] == 400){
              this.alert=data['status'] ;
          }
        
        });
      }else{
        this.otpValidation = false;
        
      }
  
      }

      ngOnInit() {
        this.otpFormGroup = this.fb.group({
          mobileCtrl:[''],
        });

        this.countryCode1 = this.route.snapshot.queryParamMap.get('c');
        if(this.countryCode1!==null){
          this.countryCode1 = this.route.snapshot.queryParamMap.get('c');
        }else{
          this.countryCode1 ="";
        }

        this.contact = this.route.snapshot.queryParamMap.get('q');
        if(this.contact!==null){
          this.contact = this.route.snapshot.queryParamMap.get('q');
        }else{
          this.contact ="";
        }

         this.user_id = this.route.snapshot.queryParamMap.get('i');
      }

      ok(){
        this.router.navigate(['auth/login'])
      }
      close()
      {
        this.alertflag=0; 
    
      }
  
      update_number_otp(){
        if(!(this.defaultCountryCode)){
          this.countryCode = '1';
        }
        var flag = true;
        var update_data = {
          mobile:'',
          phone_code : '',
          user_id : this.user_id
        }
        
        // var phone_validation = this.mobile.substring(this.mobile.length - 10, this.mobile.length);
        // var plus_symbol_validation = this.mobile.substring(0, 1);
        // var phonecode_validation = this.mobile.substring(1, this.mobile.length - 10);
        
        // if(!(plus_symbol_validation=="+")){
        //   flag = false;
        //   this.resend_input_validation = false;
        //   this.update_phone_message = " You have missed '+' symbol at start !";
        // }else if(phonecode_validation.length === 0 || phonecode_validation === ''){
          
        //   flag = false;
        //   this.update_phone_message = "You have missed phonecode !";
        //   this.resend_input_validation = false;
        // }
        // else if(this.mobile.length<=11){
        //   flag = false;
        //   this.update_phone_message = "You have missed either phonecode or mobile number !";
        //   this.resend_input_validation = false;
        // }else{
        //   flag = true;
        //   this.update_phone_message ="";
        //   update_data.mobile = phone_validation;
        //   update_data.phone_code = phonecode_validation;
            
        // }
        if(this.resendOtp){
          update_data.mobile = null;
          update_data.phone_code = null;
          update_data.user_id = this.user_id
          
        }
        if(this.resendOtpDiff){
          this.update_phone_message ="";
          update_data.mobile = this.otpFormGroup.controls.mobileCtrl.value;
          update_data.phone_code = this.countryCode;
          update_data.user_id = this.user_id
          
        }
            

       if(this.resendOtpDiff || this.resendOtp){
          this.api.UpdateNumberOTP(update_data)
          .subscribe(
            (data: any) => {
      
            if(data['status'] == 200){
              this.resend_input = true;
              this.toasterService.success('otp send successfully!!','Success',{duration: 3000});
              this.otpSent = true;
            }
            else if(data['status'] == 400){
              this.resend_input = true;
              this.toasterService.danger('Unable to send otp  Please try again','Error',{duration: 3000});
            }
          
          });
        }  
      }
    
      resend_input_open(){
        this.resend_input = false;
      }

      OnlyNoAllowed(event): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          
          return false;
        }
        return true;
        }
        
        onCountryChange(details){ 
        this.defaultCountryCode = true;
        this.countryCode = details.dialCode;
        }
        resend_otp(){
          this.resendOtp = true;
          
          this.resendOtpDiff = false;
          
        }
        resend_otp_diff(){
          this.resendOtpDiff = true;
          
          this.resendOtp = false;
          
        }
          
      
  }